import React from "react"

const ContactSidebar = function () {
  return (
    <div class="text-center py-5" style={{ fontSize: "0.7rem" }}>
      <p>Sonoma Technical Support Services</p>
      <p>•••••••••••••••••••••••••••</p>
      <p>
        230-1210 Summit Drive
        <br />
        Suite #459
        <br />
        Kamloops, BC
        <br />
        V2C 6M1
      </p>
      <p>•••••••••••••••••••••••••••</p>
      <p>1-866-898-3123</p>
    </div>
  )
}

export default ContactSidebar
